import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TokenService } from '@shared/service/app/token.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _router: Router,
        private location: Location
        // private _tokenService: TokenService,
    ) { }

    ngOnInit(): void {
        /**
         * Metodos para iniciar sesion al momento en que en la URL venga un Token 
         */
        window.addEventListener('storage', (event) => {
            if (event.key === 'usuarioURL' || event.key === 'token' || event.key === null) {
                // Se refresca la página si se actualiza un valor relevante o si se limpió el localStorage
                window.location.reload();
            }
        });
        const url = this.location.path(true);
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const token = urlParams.get('token');

        if (token) {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('usuarioURL', token);
            console.log(localStorage.getItem('usuarioURL'));
        }
    }
}