//const $BASE_API = 'https://apituregistro.desajeds.com.ar';
const $BASE_REST = 'https://apituregistro.desajeds.com.ar/web';
// const $BASE_REST = "http://localhost/tu_registro_backend/web";
//const $BASE_REST = "http://192.168.0.67/tu_registro_backend/web";


export const environment = {
      production: false,
      baseRest: $BASE_REST,
      login: {
            users: `${$BASE_REST}/view_usuario_logins`,
            //users: `${$CARGA_WEB}/usuarios_login.php`,
            login: `${$BASE_REST}/pev_seg_usuario/login`,
            recuperar: `${$BASE_REST}/pev_seg_usuario_data/recuperar`,
            cambiar_pass: `${$BASE_REST}/pev_seg_usuario_data/cambiar_pass`,
            salir: `${$BASE_REST}/pev_seg_usuario_data/salir`,
      },
      select: {
            configuracion: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=`,
            buscadorEmpresa: `${$BASE_REST}/select?modelo=PevEmpEmpresa&campo_id=idempresa&campo_descripcion=razon_social&campo_activo=`,
            roles: `${$BASE_REST}/selects?modelo=PevSegRol&campo_id=idrol&campo_descripcion=descripcion&campo_activo=activo`,
            paises: `${$BASE_REST}/selects?modelo=PevComPais&campo_id=idpais&campo_descripcion=descripcion&campo_activo`,
            provincia: `${$BASE_REST}/select/provincia?idpais=`,
            localidad: `${$BASE_REST}/select/localidad?idprovincia=`,
            tipo_proyecto: `${$BASE_REST}/selects?modelo=PevProProyectoTipo&campo_descripcion=descripcion&campo_id=idproyectotipo&campo_activo=activo`,
            proyecto: `${$BASE_REST}/select?modelo=PevProProyecto&campo_id=idproyecto&campo_descripcion=nombre&campo_activo=`,
            genero: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=22`,
            tipo_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=23`,
            genero_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=24`,
            talle_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=25`,
            disciplina: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=11`,
            estado_evento: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=26`,
            disparador: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=27`,
            medios_pago: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=28`
      },
      pagos: {
            //publicKey:"APP_USR-0af9aa15-e6a9-4829-9504-f06335450481"//produccion
           // publicKey: "TEST-122154c2-7545-411b-9895-09d7b2aaded3"//anterior
             publicKey: "TEST-985fbe21-c074-4e6f-8651-e36a8d426841"//nueva
            /* Public Key
            TEST-985fbe21-c074-4e6f-8651-e36a8d426841
            Access Token
            TEST-3107286589395267-110108-3b7d290d9991a87e4b4ef7ff8807808d-97142057 */
      }
};
