import { Route } from '@angular/router';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'home' }, //antes accedia a esta pagina por defecto si pasaba algo
    { path: '', pathMatch: 'full', redirectTo: 'panel-inscripcion' },

    // Redirect 'denegado' path to '/acceso-denegado'
    { path: 'acceso-denegado', pathMatch: 'full', redirectTo: 'acceso-denegado' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'panel-inscripcion' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'validated-user', loadChildren: () => import('./modules/auth/validated-user/validated-user.routes') },
            { path: 'confirmation-required', loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('./modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('./modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.routes') },
            { path: 'acceso-denegado', loadChildren: () => import('./modules/admin/acceso-denegado/acceso-denegado.routes') },
            { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
            { path: 'panel-inscripcion', loadChildren: () => import('./modules/panel-inscripcion/panel-inscripcion.module').then(m => m.PanelInscripcionModule) },
            { path: 'user', loadChildren: () => import('./layout/common/user/user.module').then(m => m.UserModule) },
        ]
    }

];