import { AuthUtils } from 'app/core/auth/auth.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵpatchComponentDefWithScope } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { IUsuario, User } from '@core/user/user.types';
import { Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private user: IUsuario;
    avisado = false;
    headers = new HttpHeaders()
    .set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    ) { }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Métodos públicos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Recuperar contraseña
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

        
    /**
     * Restablecer contraseña
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Iniciar sesión
     *
     * @param credentials
     */
    signIn(credentials): Observable<any> {
        console.log(credentials)
        return this._httpClient.post(`${environment.login.login}`, credentials).pipe(
            switchMap((response: any) => {
                if (response.success) {
                    // Almacenar el token de acceso en el almacenamiento local
                    this.accessToken = response.token;
                    // Atencion sí cambia el estado tira 'undefined' y hay que volver a loguearse
                    console.log(response)
                    const usuario: User = {
                        id: response.usuario.codigo,
                        name: response.usuario.nick,
                        avatar: response.usuario.foto_app,
                        email: response.usuario.email,
                        status: response.usuario.activo
                    }
                    // Almacenar el usuario en el servicio de usuario
                    this._userService.user = usuario;
                    
                    // Establecer el indicador de autenticación en verdadero
                    this._authenticated = true;
                    
                    // Devolver un nuevo observable con la respuesta
                    return of(response);
                } else {
                    // Si no hay éxito, lanzar un error
                    return throwError("Inicio de sesión fallido");
                }
            })
        );
    }
    signIn2(dataUser:any, token:string): void {
        const usuario : User = {
            id: dataUser.usuario.idusuario,
            name: dataUser.usuario.nombre,
            avatar: 'avatars/female-16.jpg',
            email: dataUser.usuario.mail,
            status: 'true'
        }
        this.accessToken = token;
        this._userService.user = usuario        
        this._authenticated = true;

    }
    /**
     * Cerrar sesión
     */
    signOut(): Observable<any> {
        // Eliminar el token de acceso del almacenamiento local
        localStorage.clear();
        // Establecer el indicador de autenticación en falso
        this._authenticated = false;
        // Devolver el observable
        return of(true);
    }

    renewToken(): Observable<any> {
        return this._httpClient.post<any>(environment.login.users, null);
    }

    /**
     * Registrarse
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Desbloquear sesión
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Verificar el estado de autenticación
     */
    check(): Observable<boolean> {
        // Comprobar si el usuario ha iniciado sesión
        if (this._authenticated) {
            return of(true);
        }

        // Comprobar la disponibilidad del token de acceso
        if (!this.accessToken) {
            return of(false);
        }

        // Comprobar la fecha de vencimiento del token de acceso
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // Si el token de acceso existe y no ha caducado, iniciar sesión con él
        return of(true);
    }

    getDataUser() {
        return this.user;
    }
    salir(): Observable<any> {
        //  const url = `${this.API_URL}/save_datos_configuracion`
          return this._httpClient.post(environment.login.salir,'', { headers: this.headers })
        }
}
